<template>
  <div>
    <div style="text-align:left;">
      <el-input
        placeholder="请输入关键字(电话号或用户编号)"
        clearable
        prefix-icon="el-icon-search"
        v-model="input2"
        @clear="clear"
        style="width:280px;margin-right:10px;"
        size="small"
      >
      </el-input>
      <el-button type="primary" @click="ChangeServeQuery()" size="small">查询</el-button>
    </div>
    <el-table :data="list" border style="width: 100%; margin-top:20px">
      <el-table-column prop="id" label="ID" v-if="false"></el-table-column>
      <el-table-column prop="userName" label="姓名"></el-table-column>
      <el-table-column prop="userDesc" label="备注"></el-table-column>
      <el-table-column prop="agent" label="操作人编号"></el-table-column>
      <el-table-column prop="from" label="转出账号"></el-table-column>
      <el-table-column prop="to" label="转入账号"></el-table-column>
      <el-table-column prop="allotTime" label="转移时间"></el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      input2: "",
      list: [],
    };
  },
  methods: {
    clear() {
      this.list = [];
      this.input2 = "";
    },
    //修改服务人查询用户
    ChangeServeQuery(a) {
      if (this.input2 == "") {
        this.$message({
          message: "请输入关键字",
          type: "error",
        });
        return;
      }
      this.axios.get("/gu/get_cust_info_by_custid?cust_id=" + this.input2).then((res) => {
        if (res.data.code == 0) {
          this.getservice(res.data.result.Tel1);
        } else {
          this.getservice(this.input2);
        }
      });
    },
    getservice(val) {
    //   console.log(val);
      this.axios
        .get(this.serveurl.serve + "/api/User/get_service_change_record?userId=" + val)
        .then((res) => {
          if (res.data.code == 1) {
            if (res.data.result.length > 0) {
              this.list = res.data.result;
            } else {
              this.$message({
                message: "没有数据",
                type: "error",
              });
            }
          }
        })
        .catch((res) => {});
    },
  },
};
</script>
